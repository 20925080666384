export interface StatamicEntriesRequest {
    collection: string
    key?: string
    value?: string | number
}

export interface StatamicEntriesResponse<T> {
    data: T[]
}

export const useStatamicEntries = () => {
    const statamic = useStatamic()

    const getEntries = async <T>(data: StatamicEntriesRequest): Promise<T[]> => {
        const res = await statamic<StatamicEntriesResponse<T>>(
            `/collections/${data.collection}/entries`,
        )

        return res.data
    }

    const getEntryBy = async <T>(data: StatamicEntriesRequest): Promise<T> => {
        const res = await statamic<StatamicEntriesResponse<T>>(
            `/collections/${data.collection}/entries?filter[${data.key}:equals]=${data.value}`,
        )

        if (!res.data) {
            throw createError({
                statusCode: 404,
                statusMessage: 'Invalid collection',
                fatal: true,
            })
        }

        if (res.data.length == 0) {
            throw createError({
                statusCode: 404,
                statusMessage: 'Entry not found',
                fatal: true,
            })
        }

        return res.data[0]
    }

    const getEntryById = async <T>(data: StatamicEntriesRequest): Promise<T> => {
        return getEntryBy<T>({ ...data, key: 'id' })
    }

    const getEntryBySlug = async <T>(data: StatamicEntriesRequest): Promise<T> => {
        return getEntryBy<T>({ ...data, key: 'slug' })
    }

    return {
        getEntries,
        getEntryBy,
        getEntryById,
        getEntryBySlug,
    }
}
