import Breakpoint from './Breakpoint'

export class OffsetItem implements Offset {
    left: number
    right: number
    isComplementary: boolean

    static fromData(data?: Offset) {
        return new this(data?.left ?? 0, data?.right ?? 0)
    }

    static random(breakpoint: Breakpoint, requiresComplementary: boolean = false) {
        if (breakpoint === Breakpoint.default) {
            randomValueFromArray([new OffsetItem(0, 1), new OffsetItem(1, 0)])
        }

        const cols = gridColCount(breakpoint)
        const widths = [7, 7, 8, 8, 8]

        if (!requiresComplementary) {
            // only use these larger sizes if we don't expect a CTA next to this item
            widths.push(10)
            widths.push(14)
        }

        const width = randomValueFromArray(widths)
        const left = Math.floor(Math.random() * (cols - 1 - width)) + 2

        return new OffsetItem(left, cols - width - left)
    }

    constructor(left: number = 0, right: number = 0, isComplementary: boolean = false) {
        this.left = left
        this.right = right
        this.isComplementary = isComplementary
    }

    get largest(): number {
        return Math.max(this.left, this.right)
    }

    get colStart(): number {
        return this.left + 1
    }

    get colSpan(): number {
        return 16 - this.left - this.right
    }

    get positionedLeft(): boolean {
        return this.left === 0
    }

    /**
     * Returns an offset that will be positioned in, and will take up, the largest
     * available space next to this offset.
     */
    complementary(breakpoint: Breakpoint): OffsetItem {
        // the complementary offset will take up the remaining space on the opposite side
        const colSpan = gridColCount(breakpoint) - this.largest

        if (this.left > this.right) {
            // largest space is on the left
            return new OffsetItem(0, colSpan, true)
        } else {
            // largest space is on the right
            return new OffsetItem(colSpan, 0, true)
        }
    }

    classes(breakpoint: Breakpoint = Breakpoint.lg): string {
        const totalColumns = gridColCount(breakpoint)
        let colStart = this.left + 1
        let colSpan = totalColumns - this.left - this.right

        if (this.isComplementary && breakpoint === Breakpoint.default) {
            // on mobile, complementary items should take up the full width
            colStart = 2
            colSpan = totalColumns - 1
        }

        const classes: string[] = []

        // only set gutters if we're not at the default (mobile) breakpoint
        if (breakpoint !== Breakpoint.default) {
            if (!this.left) {
                // no left offset, add a left gutter
                classes.push(`${breakpoint}gutter-l`)
            } else {
                // no left gutter
                classes.push(`${breakpoint}gutter-l-0`)
            }

            if (!this.right) {
                // no right offset, add a right gutter
                classes.push(`${breakpoint}gutter-r`)
            } else {
                // no right gutter
                classes.push(`${breakpoint}gutter-r-0`)
            }
        }

        if (this.left > 0) {
            // left offset; set the start column
            classes.push(`${breakpoint}col-start-${colStart}`)
        }

        if (this.isComplementary) {
            if (breakpoint === Breakpoint.default) {
                // on mobile, force all complementary items to be last
                classes.push(`${breakpoint}order-last`)
            } else {
                // above mobile, complementary items should be explicitly ordered first (if on the left) or last (if on the right)
                if (this.positionedLeft) {
                    classes.push(`${breakpoint}order-first`)
                } else {
                    classes.push(`${breakpoint}order-last`)
                }
            }
        }

        // always set the column span
        classes.push(`${breakpoint}col-span-${colSpan}`)

        return classes.join(' ')
    }
}
