export interface EntryItem {
    id: number
    url?: string
    parent?: number
    title?: string
    slug?: string
}

export class Entry {
    static async errorPage<T = EntryItem>(): Promise<T> {
        return this.fromSlug('pages', 'page-not-found')
    }

    static async fromId<T>(collection: string, id: number): Promise<T> {
        const { getEntryById } = useStatamicEntries()
        const { data } = await useAsyncData(`entries/${collection}/${id}`, () =>
            getEntryById<T>({ collection, value: id }),
        )

        if (!data.value) {
            return this.errorPage()
        }

        return data.value as T
    }

    static async fromSlug<T = EntryItem>(collection: string, slug: string): Promise<T> {
        const { getEntryBySlug } = useStatamicEntries()
        const { data } = await useAsyncData(`entries/${collection}/${slug}`, () =>
            getEntryBySlug<T>({ collection, value: slug }),
        )

        if (!data.value) {
            return this.errorPage()
        }

        return data.value as T
    }

    static async all<T = EntryItem>(collection: string) {
        const { getEntries } = useStatamicEntries()
        const { data } = await useAsyncData(`entries/${collection}`, () =>
            getEntries<T[]>({ collection }),
        )

        return data.value as T[]
    }
}
