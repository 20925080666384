/**
 * these are the Tailwind breakpoint prefixes:
 * https://tailwindcss.com/docs/responsive-design
 */

enum Breakpoint {
    // name = prefix
    default = '',
    sm = 'sm:',
    md = 'md:',
    lg = 'lg:',
    xl = 'xl:',
    '2xl' = '2xl:',
}

export default Breakpoint
