import type { LocationQueryValue, RouteLocationNormalized } from 'vue-router'
export interface Preview {
    token: string | LocationQueryValue[]
}

export default defineNuxtPlugin(() => {
    const preview: Ref<Preview | null> = ref(null)

    const previewMiddleware = async (to: RouteLocationNormalized) => {
        const isPreview = to.query.preview && to.query.preview === 'true'

        if (isPreview && to.query.token) {
            preview.value = { token: to.query.token }
            return
        }

        if (preview.value) {
            // If leaving the preview page, refresh the page to exit preview mode
            window.location.href = to.fullPath
            return
        }
    }

    addRouteMiddleware('preview', previewMiddleware, { global: true })

    return { provide: { preview } }
})
