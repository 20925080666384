<script setup lang="ts">
    import * as Sentry from '@sentry/nuxt'

    const isDev = import.meta.dev
    const error = await useError()
    const errorRegex = /\((.*?):(\d+):(\d+)\)/
    const matches = errorRegex.exec(error.value.stack)
    const lineNumber = matches ? (matches[2] ?? null) : null
    let fileName = matches ? (matches[1] ?? '') : ''
    console.log('fineName', fileName)

    fileName = fileName.replace(`${window.location.origin}/_nuxt/`, '')

    if (fileName.indexOf('?t') > -1) {
        fileName = './' + fileName.substring(0, fileName.lastIndexOf('?t'))
    }

    useHead({
        title: 'Something went wrong • ROOM',
    })

    onMounted(() => {
        Sentry.setContext('Error Details', {
            fileName,
            lineNumber,
            nuxtError: error.value,
        })

        // @ts-ignore non-standard Error constructor
        Sentry.captureException(new Error(error.value.message, fileName, lineNumber))
    })
</script>

<template>
    <div v-if="error" class="flex h-screen items-center justify-center">
        <div class="gutter w-full" :class="{ 'max-w-[600px]': !isDev, 'max-w-[1024px]': isDev }">
            <p class="mt-4 text-3xl uppercase text-dark/40">Error {{ error?.statusCode }}</p>
            <h1 class="text-3xl uppercase">Sorry, something went wrong.</h1>

            <button
                class="icon-arrow-northeast pt-8 text-xl uppercase"
                type="button"
                @click="clearError({ redirect: '/' })"
            >
                Try again
            </button>

            <div v-if="isDev" class="mt-8 border-t border-medium pt-8">
                <p>
                    <code>{{ error.message }}</code>

                    <template v-if="fileName">
                        in
                        <code>{{ fileName }}</code>

                        <template v-if="lineNumber">
                            at line
                            <code>{{ lineNumber }}</code>
                        </template>
                    </template>
                </p>

                <pre
                    class="mt-4 max-h-[40vh] overflow-auto text-dark/70"
                    v-html="error.stack"
                ></pre>
            </div>
        </div>
    </div>
</template>

<style scoped>
    code {
        @apply font-bold;
    }
</style>
