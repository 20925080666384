<script lang="ts" setup>
    import { OffsetGroup } from '~/data/OffsetGroup'

    interface Props {
        asset?: Asset
        size?: string
        randomOffset?: boolean
        embiggenable?: boolean
        fit?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        size: 'large',
        randomOffset: false,
        embiggenable: false,
        fit: 'default',
    })

    const offset = props.randomOffset ? OffsetGroup.random() : null
</script>

<template>
    <figure class="asset size-full" :class="{ [offset?.classes ?? '']: offset }" v-if="asset">
        <div class="relative size-full overflow-hidden">
            <video
                v-if="asset.is_video"
                :src="asset.url"
                playsinline
                muted
                loop
                autoplay
                class="h-full w-full object-cover"
            />

            <img
                v-else
                :src="asset?.sizes[size] ?? asset.url"
                :alt="asset.alt ?? ''"
                class="scale inset-0 size-full transition"
                :class="{
                    'absolute object-cover': fit == 'cover',
                    'absolute object-contain': fit == 'contain',
                }"
            />
        </div>

        <figcaption v-if="$slots.default" class="mt-3">
            <slot />
        </figcaption>
    </figure>
</template>

<style>
    a .asset img {
        transform: scale(1.03);
    }

    a:hover .asset img {
        transform: scale(1);
    }
</style>
