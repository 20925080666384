<script lang="ts" setup>
    import { Validation } from '~/data/Validation'

    export interface Props {
        field?: Field
        taxonomies?: string[]
        label?: string
        placeholder?: string
        default?: string
        showLabel?: boolean
        required?: boolean
        width?: number
        fitContent?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        label: '',
        placeholder: '',
        default: '',
        showLabel: true,
        required: false,
        width: 100,
        fitContent: false,
    })

    const model = defineModel()
    const { getTaxonomyTerms } = useStatamicTaxonomies()

    /** @todo: support multiple terms */
    let taxonomy: string | undefined

    if (props.taxonomies && props.taxonomies.length > 0) {
        taxonomy = props.taxonomies[0]
    } else if (props.field && props.field.taxonomies && props.field.taxonomies.length > 0) {
        taxonomy = props.field.taxonomies[0]
    }

    if (!taxonomy) {
        throw new Error('No taxonomy provided')
    }

    const { data } = await useAsyncData(`terms/${taxonomy}`, () => getTaxonomyTerms({ taxonomy }))

    const field = {
        type: 'select',
        validate: props.required ? [Validation.required] : [],
        handle: taxonomy,
        display: props.label,
        width: props.width,
        placeholder: props.placeholder,
        options: data.value?.map((term) => ({
            key: term.slug ?? '',
            value: term.title,
        })),
    } as Field

    if (props.field) {
        field.handle = props.field.handle
        field.display = props.field.display
        field.width = props.field.width
        field.placeholder = props.field.display
    }
</script>

<template>
    <InputSelect
        :field="field"
        :showLabel="showLabel"
        :default="props.default || ' '"
        v-model="model"
        :fitContent="fitContent"
    />
</template>
