export interface NavItem {
    depth: number
    children: NavItem[]
    page: {
        id: string
        title: string
        url: string
    }
}

export class Nav<T = NavItem> {
    private _handle: string
    private _items: T[]

    constructor(handle: string, items: T[] = []) {
        this._handle = handle
        this._items = items
    }

    get handle(): string {
        return this._handle
    }

    get items(): T[] {
        return this._items
    }

    static async fromHandle<T = NavItem>(handle: string): Promise<Nav<T>> {
        const { getNavTree } = useStatamicNavTree()
        const { data } = await useAsyncData<StatamicNavItem<unknown>[]>(`navTree/${handle}`, () =>
            getNavTree({ handle }),
        )

        return new Nav<T>(handle, data.value as T[])
    }
}
