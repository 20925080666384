const doubleRequestAnimationFrame = (callback: Function) => {
    requestAnimationFrame(() => {
        requestAnimationFrame(callback as FrameRequestCallback)
    })
}

const forceNextTick = (callback: Function) => {
    doubleRequestAnimationFrame(callback)
}

export default forceNextTick
