<script lang="ts" setup>
    const props = defineProps({
        taxonomy: {
            type: String,
            required: true,
        },
    })

    const { getTaxonomyTerms } = useStatamicTaxonomies()

    const { data } = await useAsyncData(`terms/${props.taxonomy}`, () =>
        getTaxonomyTerms({ taxonomy: props.taxonomy }),
    )
</script>

<template>
    <ul>
        <li v-for="term in data" class="pb-1 lg:pb-0">
            <NuxtLink :to="term.url" class="transition hover:text-medium">
                {{ term.title }}
            </NuxtLink>
        </li>
    </ul>
</template>
