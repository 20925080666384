<script lang="ts" setup>
    const overlayVisible = ref(false)
</script>

<template>
    <div class="pointer-events-none fixed inset-0 z-[1000]">
        <div class="opacity-0 transition" :class="{ 'opacity-10': overlayVisible }">
            <div class="desktop layout-grid invisible absolute inset-0 bg-white md:visible">
                <div class="h-full bg-dark" v-for="i in 16"></div>
            </div>

            <div class="mobile layout-grid absolute inset-0 bg-white md:invisible">
                <div class="h-full bg-dark" v-for="i in 6"></div>
            </div>
        </div>

        <label
            class="pointer-events-auto absolute bottom-4 right-4 z-[1000] flex gap-2 rounded-full bg-dark px-3 py-1 text-sm shadow"
        >
            <input type="checkbox" v-model="overlayVisible" />
            <span class="text-sm text-white opacity-80">Grid overlay</span>
        </label>
    </div>
</template>
