<script lang="ts" setup>
    import { GlobalItem } from '~/data/GlobalItem'
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { OffsetItem } from '~/data/OffsetItem'

    interface SiteFooter {
        project_cat_title: string
        social_icons_title: string
        pages_listing_title: string
        project_cat_display: boolean
    }

    const footerOptions = await GlobalItem.fromHandle<SiteFooter>('site_footer')

    const formOffset = new OffsetGroup(new OffsetItem(3, 2), new OffsetItem(1, 1))

    const expandSibling = (e: Event) => {
        const targetEl = e.target as HTMLElement
        const content = targetEl.nextSibling as HTMLElement

        if (content.classList.contains('is-expanded')) {
            // content is currently expanded - let's hide it

            content.classList.remove('is-expanded')

            // set the content height to the scroll height, so that we can transition to 0
            content.style.height = `${content.scrollHeight}px`

            // once the DOM has repainted, set the height to 0
            forceNextTick(() => {
                content.style.height = '0'
            })
        } else if (content.clientHeight === 0) {
            // height of content is 0, so content is hidden - let's show it

            content.classList.add('is-expanded')

            const controller = new AbortController()

            // once the height transition has completed, set the content height to auto
            content.addEventListener(
                'transitionend',
                () => {
                    content.style.height = 'auto'

                    // get rid of the event listener
                    controller.abort()
                },
                { signal: controller.signal },
            )

            // set the content height to the scroll height to trigger the transition
            content.style.height = `${content.scrollHeight}px`
        }
    }
</script>

<template>
    <InfoPages />

    <OurPartners v-if="currentSlug() == 'home'" />

    <div class="gutter-b bg-medium">
        <RoomToText content="Plan" :toOffset="12" />

        <div class="layout-grid">
            <div :class="formOffset.classes">
                <FooterForm />
            </div>
        </div>
    </div>

    <footer
        class="lg:layout-grid gutter lg:gutter-x-0 relative flex flex-col items-start overflow-hidden bg-dark uppercase text-white"
    >
        <div class="lg:gutter-l col-span-8 mb-16 justify-self-start lg:mb-0">
            <RoomLogo class="bg-white" />
        </div>

        <div class="col-span-3 w-full 2xl:col-span-2">
            <h3 @click="expandSibling">{{ footerOptions.pages_listing_title }}</h3>

            <div class="h-0 overflow-hidden transition-all lg:h-auto">
                <NavDisplay handle="main" class="pb-12 lg:pb-0" itemClass="pb-1 lg:pb-0" />
            </div>
        </div>

        <div class="col-span-3 w-full 2xl:col-span-2" v-if="footerOptions.project_cat_display">
            <h3 @click="expandSibling">{{ footerOptions.project_cat_title }}</h3>

            <div class="h-0 overflow-hidden transition-all lg:h-auto">
                <TaxonomyTerms taxonomy="categories" class="pb-12 lg:pb-0" />
            </div>
        </div>

        <div class="col-span-2 w-full">
            <h3 @click="expandSibling">{{ footerOptions.social_icons_title }}</h3>

            <div class="h-0 overflow-hidden transition-all lg:h-auto">
                <NavSocials class="pb-12 lg:pb-0" />
            </div>
        </div>

        <div
            class="lg:gutter-l order-last col-span-8 col-start-1 mt-12 flex gap-16 text-medium lg:order-none lg:mt-0 lg:self-end"
        >
            <p>&copy; ROOM {{ new Date().getFullYear() }}</p>
            <NavDisplay handle="bottom" linkClass="hover:!text-light" />
        </div>

        <div class="col-span-4 col-start-9 flex w-full flex-wrap lg:mt-16">
            <div class="w-full">
                <h3 @click="expandSibling">Subscribe</h3>

                <div class="relative h-0 overflow-hidden transition-all lg:h-auto">
                    <FormDisplay
                        handle="subscribe"
                        :fieldLabels="false"
                        :submitArrow="true"
                        :blendedInputs="true"
                        class="pr-4"
                    />
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
    h3 {
        @apply text-medium;
        @apply w-full py-2;
        @apply border-t border-white lg:border-0;
    }

    footer:after {
        @apply absolute bottom-0 left-0 right-0 h-4 translate-y-1/2 bg-dark content-[''];
    }
</style>

<style>
    form[data-handle='subscribe'] {
        button {
            @apply absolute;
        }

        .input-frame {
            @apply my-0;
        }

        input {
            @apply text-light;
        }

        .form-error,
        .form-success {
            @apply z-20 !bg-dark text-light;

            div {
                @apply left-0 right-0 translate-x-0 border-0 !bg-dark !px-0 !py-0;
            }
        }
    }
</style>
