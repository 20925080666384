export interface StatamicNavItem<T> {
    data: NonNullable<T>
}

export interface StatamicNavTreeRequest {
    handle: string
}

export interface StatamicNavTreeResponse<T> {
    data: StatamicNavItem<T>[]
}

export const useStatamicNavTree = () => {
    const statamic = useStatamic()

    const getNavTree = async <T>(data: StatamicNavTreeRequest): Promise<StatamicNavItem<T>[]> => {
        const res = await statamic<StatamicNavTreeResponse<T>>(`/navs/${data.handle}/tree`)
        return res.data
    }

    return {
        getNavTree,
    }
}
