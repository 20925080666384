export const useEscapeKey = (onEscPressed: Function) => {
    const keyPressed = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            onEscPressed()
        }
    }

    onMounted(() => {
        document.addEventListener('keyup', keyPressed)
    })

    onUnmounted(() => {
        document.removeEventListener('keyup', keyPressed)
    })
}
