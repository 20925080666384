<script lang="ts" setup>
    import { Nav, type NavItem } from '~/data/Nav'

    defineProps({
        itemClass: String,
        linkClass: String,
    })

    interface SocialNavItem extends NavItem {
        icon: Asset
    }

    const nav = await Nav.fromHandle<SocialNavItem>('socials')
</script>

<template>
    <ul class="transition-staggered flex gap-3">
        <li
            v-for="item in nav.items"
            class="block transition"
            :class="itemClass"
            :style="`--icon: url(${item.page.icon.url})`"
        >
            <NuxtLink
                :to="item.page.url"
                v-if="item.page.icon"
                class="block bg-white transition hover:bg-medium"
                :class="linkClass"
                target="_blank"
            ></NuxtLink>
        </li>
    </ul>
</template>

<style scoped>
    a {
        width: 34px;
        height: 34px;
        mask: var(--icon) no-repeat center / contain;
    }
</style>
