<script lang="ts" setup>
    import { Validation } from '~/data/Validation'

    export interface Props {
        field: Field
        modelValue?: string
        showLabel?: boolean
        fitContent?: boolean
        inputClasses?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        open: false,
        showLabel: true,
        fitContent: false,
        inputClasses: '',
    })

    const emit = defineEmits(['update:modelValue'])

    const placeholder = computed(() => {
        let placeholder = props.field.placeholder ?? ''

        if (props.field.validate?.indexOf(Validation.required) !== -1) {
            if (placeholder === '') {
                placeholder = props.field.display
            }

            placeholder += ' *'
        }

        return placeholder.toUpperCase()
    })

    const valueHolder = ref()
    const inputEl = ref()

    const updateInputWidth = () => {
        if (!inputEl.value) {
            return
        }

        setTimeout(() => (inputEl.value.style.width = `${valueHolder.value.clientWidth + 50}px`), 1)
    }

    if (props.fitContent) {
        watch(
            () => props.modelValue,
            () => updateInputWidth(),
        )

        onMounted(() => {
            new EfficientResizeListener(updateInputWidth)
        })
    }
</script>

<template>
    <InputFrame :field="field" :showLabel="showLabel" :fitContent="fitContent">
        <span
            v-if="fitContent"
            ref="valueHolder"
            class="value-holder invisible absolute normal-case"
        >
            {{ props.modelValue ? props.modelValue : placeholder }}
        </span>

        <component
            ref="inputEl"
            :value="props.modelValue"
            @input="emit('update:modelValue', $event.target.value)"
            :is="field.type === 'textarea' ? 'textarea' : 'input'"
            :type="field.input_type"
            :name="field.handle"
            class="peer w-full bg-none px-3 py-1 text-dark outline-none transition xl:px-4 xl:py-2"
            :class="inputClasses"
            :required="field.validate?.indexOf(Validation.required) !== -1"
            :placeholder="showLabel ? null : placeholder"
        />
    </InputFrame>
</template>
