import validate from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import close_45nav_45global from "/opt/atlassian/pipelines/agent/build/frontend/middleware/close-nav.global.ts";
import statamic_45cp_45redirect_45global from "/opt/atlassian/pipelines/agent/build/frontend/middleware/statamic-cp-redirect.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  close_45nav_45global,
  statamic_45cp_45redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}