export const useNavOpen = () => {
    const isOpen = useState('isOpen', () => false)

    watch(
        () => isOpen.value,
        (isOpen) => {
            document.body.classList.toggle('nav-is-open', isOpen)
        },
    )

    return isOpen
}
