import { default as _91_91slug_93_93bmiP6XFFEXMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/[[slug]].vue?macro=true";
import { default as _91slug_93uIgjRfog9MMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/categories/[slug].vue?macro=true";
import { default as indexUIg50tPCvlMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/categories/index.vue?macro=true";
import { default as _91slug_93aVZYhu9kYcMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/projects/[slug].vue?macro=true";
import { default as indexpX4FhcnYjaMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/projects/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug?",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: "categories-slug",
    path: "/categories/:slug()",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/categories/[slug].vue").then(m => m.default || m)
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-slug",
    path: "/projects/:slug()",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/projects/[slug].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/projects/index.vue").then(m => m.default || m)
  }
]