import { defineNuxtPlugin } from '#app/nuxt'
import { LazyInputFrame, LazyInputHidden, LazyInputSelect, LazyInputTermSelect, LazyInputText } from '#components'
const lazyGlobalComponents = [
  ["InputFrame", LazyInputFrame],
["InputHidden", LazyInputHidden],
["InputSelect", LazyInputSelect],
["InputTermSelect", LazyInputTermSelect],
["InputText", LazyInputText],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
