export interface StatamicGlobalRequest<T> {
    handle: string
}

export interface StatamicGlobalResponse<T> {
    data: T
}

export const useStatamicGlobal = () => {
    const statamic = useStatamic()

    const getGlobal = async <T>(req: StatamicGlobalRequest<T>): Promise<T> => {
        const res = await statamic<{ data: T }>(`/globals/${req.handle}`)
        return res.data
    }

    return {
        getGlobal,
    }
}
