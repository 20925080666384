class EfficientScrollListener {
    private ticking: boolean = false
    private callback!: Function

    constructor(callback: Function) {
        this.callback = callback

        // listen for scroll events
        window.addEventListener('scroll', () => this.updateScrollPosition())

        // call the callback once to set the initial scroll position
        this.updateScrollPosition()
    }

    updateScrollPosition() {
        // make sure we don't do more than one thing at a time
        if (this.ticking) {
            return
        }

        // we're about to do something - prevent anything else from being done until we've done our thing
        this.ticking = true

        window.requestAnimationFrame(() => {
            // do whatever it is that needs to be done
            this.callback(window.scrollY)

            // we've finished doing things, allow other things to be done
            this.ticking = false
        })
    }
}

export default EfficientScrollListener
