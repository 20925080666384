<script lang="ts" setup>
    import { FormItem } from '~/data/FormItem'

    const handle = 'project_enquiry'
    const form = await FormItem.fromHandle(handle)

    const data: Record<string, any> = ref({})
    const loading = ref(false)
    const success: Ref<boolean | null> = ref(null)

    const setDefaults = () => {
        for (const [name, field] of Object.entries(form.fields)) {
            data.value[name] = field.default ?? ''
        }

        data.value.project_type = null
        data.value.project_category = null
    }

    onMounted(() => {
        setDefaults()
    })

    const submit = async () => {
        loading.value = true
        success.value = true

        useStatamicForms()
            .addSubmission({ handle, data: data.value })
            .then(() => {
                setDefaults()
                loading.value = false
                success.value = true

                setTimeout(() => {
                    success.value = null
                }, 7000)
            })
            .catch(() => {
                loading.value = false
                success.value = false

                setTimeout(() => {
                    success.value = null
                }, 3000)
            })
    }
</script>

<template>
    <form class="natural-language gap-10 text-xl md:text-2xl lg:text-3xl" @submit.prevent="submit">
        <div class="mb-2 flex flex-wrap items-center gap-3">
            <span class="block whitespace-nowrap sm:inline-block">Hi, my name is</span>

            <InputText
                :field="form.fields['first_name']"
                :showLabel="false"
                :fitContent="true"
                v-model="data.first_name"
            />

            <span class="whitespace-nowrap">
                <InputText
                    :field="form.fields['last_name']"
                    :showLabel="false"
                    :fitContent="true"
                    v-model="data.last_name"
                />
                ,
            </span>
        </div>

        <div class="my-2 flex flex-wrap items-center gap-3">
            <span class="whitespace-nowrap">I require</span>

            <InputTermSelect
                :field="form.fields['project_type']"
                placeholder="Project Type"
                :showLabel="false"
                :fitContent="true"
                v-model="data.project_type"
            />
        </div>

        <div class="my-2 flex flex-wrap items-center gap-3">
            <span class="whitespace-nowrap">for a</span>

            <InputTermSelect
                :field="form.fields['project_category']"
                placeholder="Project Category"
                :showLabel="false"
                :fitContent="true"
                v-model="data.project_category"
            />

            company
        </div>

        <div class="flex flex-wrap items-center gap-3">
            <span class="whitespace-nowrap">and my budget is</span>

            <InputSelect
                :field="form.fields['budget']"
                :showLabel="false"
                :fitContent="true"
                v-model="data.budget"
            />
        </div>

        <div class="my-12 flex flex-wrap items-center gap-3">
            <span class="whitespace-nowrap">Email me at</span>

            <InputText
                :field="form.fields['email_address']"
                :showLabel="false"
                :fitContent="true"
                v-model="data.email_address"
            />
        </div>

        <PrettyButton :loading="loading">Send</PrettyButton>

        <div v-if="success !== null" class="mt-8 uppercase">
            <Transition>
                <p class="transition" v-if="success">{{ form.success_message }}</p>
                <p class="transition" v-else-if="success === false">{{ form.error_message }}</p>
            </Transition>
        </div>
    </form>
</template>

<style>
    form.natural-language {
        .input-frame {
            @apply p-0;
            @apply inline-block;
            @apply my-0;

            label {
                font-size: inherit;
            }
        }

        .multiselect-single-label,
        .multiselect-placeholder {
            @apply py-0 md:py-2;
        }

        .multiselect-dropdown {
            @apply text-lg;
        }

        .multiselect-caret {
            @apply ml-0 mr-2 lg:mr-4;
            @apply w-[14px];
            order: 10;
        }

        button,
        select,
        .multiselect,
        input {
            @apply border bg-medium;

            &:focus {
                @apply shadow-none;
                @apply bg-white;
            }

            &::placeholder {
                color: inherit;
            }
        }
    }
</style>
