<template>
    <client-only>
        <div
            class="fixed left-1/2 top-0 z-[1000] my-4 -translate-x-1/2 cursor-default rounded-full bg-dark px-6 py-1 text-white opacity-70 transition hover:opacity-10"
        >
            Preview
        </div>
    </client-only>
</template>

<style scoped></style>
