import * as Sentry from '@sentry/nuxt'
import type { NitroFetchOptions } from 'nitropack'
import { joinURL, withQuery } from 'ufo'

export const useStatamic = () => {
    const { $preview } = useNuxtApp()
    const apiUrl = joinURL(useRuntimeConfig().public.statamicUrl, '/api')

    const getXsrfToken = async () =>
        $fetch(joinURL(apiUrl, '/csrf-cookie'), { credentials: 'include' })

    return async <T>(url: string, fetchOptions: NitroFetchOptions<string> = {}): Promise<T> => {
        try {
            let endpoint = joinURL(apiUrl, url)

            if ($preview.value) {
                // Add the preview token to the query string
                endpoint = withQuery(endpoint, {
                    token: $preview.value.token,
                    timestamp: Date.now(),
                })
            }

            if (import.meta.dev) {
                const prefix = fetchOptions.method ?? 'Fetch'
                console.log(`[Statamic ${prefix}]`, endpoint)
            }

            const headers: Record<string, any> = {
                ...fetchOptions.headers,
                Accept: 'application/json',
            }

            const xsrfToken = useCookie('XSRF-TOKEN')

            // ensure we have an XSRF token if we're issuing anything other than a GET request
            if (fetchOptions.method ?? 'GET' !== 'GET') {
                if (xsrfToken.value) {
                    if (import.meta.dev) {
                        console.log('[Statamic] Using existing XSRF token')
                    }
                } else {
                    if (import.meta.dev) {
                        console.log('[Statamic] Requesting XSRF token')
                    }

                    await getXsrfToken()
                }

                // add the token to the request headers
                headers['X-XSRF-TOKEN'] = xsrfToken.value
            }

            Sentry.setContext('useStatamic', {
                endpoint,
                method: fetchOptions.method,
                xsrfToken: xsrfToken.value,
            })

            const credentialsSupported = 'credentials' in Request.prototype

            return await $fetch<T>(endpoint, {
                ...fetchOptions,
                credentials: credentialsSupported ? 'include' : undefined,
                headers,
            })
        } catch (error: any) {
            console.error('[Statamic Error]', error)

            throw createError({
                statusCode: error.response?.status,
                statusMessage: error.response?.statusText,
                data: error.response?._data,
            })
        }
    }
}
