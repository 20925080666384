<script lang="ts" setup>
    interface Props {
        height?: number
    }

    const props = withDefaults(defineProps<Props>(), {
        height: 85,
    })

    const pixelHeight = computed(() => `${props.height}px`)
</script>

<template>
    <figure class="max-h-full transition-all">
        <img
            src="@/assets/room-logo.svg"
            alt="ROOM Logo"
            width="82"
            height="85"
            class="block h-full w-auto opacity-0 transition-all"
        />
    </figure>
</template>

<style scoped>
    figure {
        --height: v-bind(pixelHeight);
        height: var(--height);
        mask: url('/images/room-logo.svg') no-repeat center / contain;
    }
</style>
