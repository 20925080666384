import Breakpoint from './Breakpoint'
import { OffsetItem } from './OffsetItem'

export class OffsetGroup implements Offsets {
    offsets_large: OffsetItem
    offsets_medium: OffsetItem
    offsets_small: OffsetItem

    static default(): OffsetGroup {
        return new this()
    }

    static fromData(data: Offsets) {
        return new this(
            OffsetItem.fromData(data.offsets_large),
            OffsetItem.fromData(data.offsets_medium),
            OffsetItem.fromData(data.offsets_small),
        )
    }

    static random(requiresComplementary: boolean = false): OffsetGroup {
        return new this(
            OffsetItem.random(Breakpoint.lg, requiresComplementary),
            OffsetItem.random(Breakpoint.md, requiresComplementary),
            OffsetItem.random(Breakpoint.default, requiresComplementary),
        )
    }

    constructor(large?: OffsetItem, medium?: OffsetItem, small?: OffsetItem) {
        this.offsets_large = large ?? new OffsetItem()
        this.offsets_medium = medium ?? new OffsetItem()
        this.offsets_small = small ?? new OffsetItem()
    }

    get classes(): string {
        return [
            this.offsets_small.classes(Breakpoint.default),
            this.offsets_medium.classes(Breakpoint.md),
            this.offsets_large.classes(Breakpoint.lg),
        ].join(' ')
    }

    get complementary(): OffsetGroup {
        return new OffsetGroup(
            this.offsets_large.complementary(Breakpoint.lg),
            this.offsets_medium.complementary(Breakpoint.md),
            this.offsets_small.complementary(Breakpoint.default),
        )
    }
}
