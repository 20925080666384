export interface StatamicFormRequest<T> {
    handle: string
}

export interface StatamicFormResponse<T> {
    data: T
}

export interface StatamicFormSubmissionRequest {
    handle: string
    data: any
}

export interface StatamicFormSubmissionResponse {}

export const useStatamicForms = () => {
    const statamic = useStatamic()

    const getForm = async <T>(req: StatamicFormRequest<T>): Promise<T> => {
        const res = await statamic<{ data: T }>(`/forms/${req.handle}`)
        return res.data
    }

    const addSubmission = async (
        req: StatamicFormSubmissionRequest,
    ): Promise<StatamicFormSubmissionResponse> => {
        const res = await statamic<{ data: StatamicFormSubmissionResponse }>(
            `/forms/${req.handle}/submit`,
            { method: 'POST', body: req.data },
        )

        return res.data
    }

    return {
        getForm,
        addSubmission,
    }
}
