<script lang="ts" setup>
    import Multiselect from '@vueform/multiselect'
    import { Validation } from '~/data/Validation'

    export interface Props {
        field: Field
        default?: string
        showLabel?: boolean
        fitContent?: boolean
        modelValue?: string
    }

    const props = withDefaults(defineProps<Props>(), {
        default: '',
        showLabel: true,
        fitContent: false,
    })

    const model = defineModel()

    const placeholder = computed(() => {
        let placeholder = props.field.placeholder ?? ''

        if (props.field.validate?.indexOf(Validation.required) !== -1) {
            if (placeholder === '') {
                placeholder = props.field.display
            }

            placeholder += ' *'
        }

        return placeholder.toUpperCase()
    })

    const valueHolder = ref()

    const updateSelectWidth = () => {
        if (!valueHolder.value || !valueHolder.value.nextSibling) {
            return
        }

        setTimeout(
            () =>
                (valueHolder.value.nextSibling.style.width = `${valueHolder.value.scrollWidth}px`),
            1,
        )
    }

    if (props.fitContent) {
        watch(
            () => props.modelValue,
            () => updateSelectWidth(),
        )

        onMounted(() => {
            new EfficientResizeListener(updateSelectWidth)
        })
    }

    const options =
        props.field.options?.map((option) => ({ value: option.key, label: option.value })) ?? []
</script>

<template>
    <InputFrame :field="field" :showLabel="showLabel" :fitContent="fitContent" :modelValue="model">
        <span ref="valueHolder" class="value-holder invisible absolute" aria-hidden="true">
            <span class="multiselect peer">
                <span class="multiselect-wrapper">
                    <span class="multiselect-placeholder">
                        {{ props.modelValue ? props.modelValue : placeholder }}
                    </span>

                    <span class="multiselect-caret" aria-hidden="true"></span>
                </span>
            </span>
        </span>

        <Multiselect
            v-model="model"
            :name="field.handle"
            class="peer"
            :placeholder="showLabel ? null : placeholder"
            :options="options"
            :can-clear="false"
        />
    </InputFrame>
</template>
