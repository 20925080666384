export enum Validation {
    required = 'required',
    email = 'email',
    url = 'url',
    min = 'min',
    max = 'max',
    numeric = 'numeric',
    alpha = 'alpha',
    alpha_dash = 'alpha_dash',
    alpha_num = 'alpha_num',
    in = 'in',
    not_in = 'not_in',
    regex = 'regex',
    date = 'date',
    date_format = 'date_format',
    date_equals = 'date_equals',
    date_before = 'date_before',
    date_after = 'date_after',
    timezone = 'timezone',
    phone = 'phone',
    uuid = 'uuid',
}
