<script lang="ts" setup>
    import { FormItem } from '~/data/FormItem'

    interface Props {
        handle: string
        fieldLabels?: boolean
        submitArrow?: boolean
        submitLabel?: string
        blendedInputs?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        fieldLabels: true,
        submitArrow: false,
        submitLabel: 'Send',
        blendedInputs: false,
    })

    const formEl = ref()
    const form = await FormItem.fromHandle(props.handle)
    const data: Record<string, any> = ref({})
    const loading = ref(false)
    const success: Ref<boolean | null> = ref(null)

    const setDefaults = () => {
        for (const [name, field] of Object.entries(form.fields)) {
            data.value[name] = field.default ?? ''
        }
    }

    onMounted(() => {
        setDefaults()
    })

    const submit = async () => {
        loading.value = true

        useStatamicForms()
            .addSubmission({ handle: props.handle, data: data.value })
            .then(() => {
                setDefaults()
                loading.value = false
                success.value = true

                setTimeout(() => {
                    success.value = null
                }, 7000)
            })
            .catch(() => {
                loading.value = false
                success.value = false

                setTimeout(() => {
                    success.value = null
                }, 3000)
            })
    }
</script>

<template>
    <form
        ref="formEl"
        @submit.prevent="submit"
        :class="{ 'blended-inputs': blendedInputs }"
        class="relative"
        :data-handle="handle"
    >
        <Transition>
            <div v-if="success" class="form-success absolute inset-0 z-10 bg-light/90">
                <div class="middle border-2 bg-light px-6 py-2">
                    {{ form.success_message }}
                </div>
            </div>
        </Transition>

        <Transition>
            <div v-if="success === false" class="form-error absolute inset-0 z-10 bg-light/90">
                <div class="middle border-2 bg-light px-6 py-2">
                    {{ form.error_message }}
                </div>
            </div>
        </Transition>

        <template v-for="(field, name) in form.fields" :key="name">
            <InputHidden v-if="field.visibility === 'hidden'" :name="name" :field="field" />

            <InputText
                v-else-if="field.type === 'text' || field.type === 'textarea'"
                :name="name"
                :field="field"
                :showLabel="fieldLabels"
                v-model="data[name]"
            />

            <InputSelect
                v-else-if="field.type === 'select'"
                :name="name"
                :field="field"
                :showLabel="fieldLabels"
                v-model="data[name]"
            />

            <InputTermSelect
                v-else-if="field.type === 'terms'"
                :name="name"
                :field="field"
                :showLabel="fieldLabels"
                v-model="data[name]"
            />
        </template>

        <PrettyButton
            type="submit"
            :class="{
                'icon-arrow absolute bottom-0 right-4 z-10 mt-10 w-[18px] border-0 after:bg-medium after:transition hover:!bg-transparent hover:after:translate-x-1/2 hover:after:bg-white':
                    submitArrow,
                'after:opacity-0': submitArrow && loading,
                'mt-10 w-full': !submitArrow,
            }"
            :loading="loading"
        >
            <template v-if="!submitArrow">Submit</template>
        </PrettyButton>
    </form>
</template>

<style>
    form.blended-inputs {
        input {
            @apply pl-0;
            @apply bg-transparent;
            @apply border-0 border-b border-b-medium;
            @apply placeholder:text-[#ffffff60];
        }
    }
</style>
