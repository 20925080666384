export const currentSlug = () => {
    const route = useRoute()
    const slug = Array.isArray(route.params.slug) ? route.params.slug[0] : route.params.slug

    if (!slug) {
        return 'home'
    }

    return slug
}
