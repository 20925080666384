export interface StatamicTaxonomy {
    title: string
    handle: string
    url?: string
}

export interface StatamicTerm {
    id: string
    url?: string
    slug?: string
    title: string
    taxonomy: StatamicTaxonomy
    order?: number
}

export interface StatamicTaxonomyTermsRequest {
    taxonomy: string
}

export interface StatamicTaxonomyTermsResponse<T> {
    data: T[]
}

export const useStatamicTaxonomies = () => {
    const statamic = useStatamic()

    const getTaxonomyTerms = async <T extends StatamicTerm = StatamicTerm>(
        data: StatamicTaxonomyTermsRequest,
    ): Promise<T[]> => {
        const res = await statamic<StatamicTaxonomyTermsResponse<T>>(
            `taxonomies/${data.taxonomy}/terms`,
        )

        if (res.data[0] && res.data[0].hasOwnProperty('order')) {
            res.data.sort((a, b) => {
                if (a.order && b.order) {
                    return a.order - b.order
                }

                return 0
            })
        }

        return res.data
    }

    return {
        getTaxonomyTerms,
    }
}
