<script lang="ts" setup>
    import { Nav, type NavItem } from '~/data/Nav'

    type InfoPageNavItem = NavItem & {
        page: {
            image: Asset
        }
    }

    const nav = await Nav.fromHandle<InfoPageNavItem>('info_pages')
</script>

<template>
    <ul
        class="block-gap-t md:gutter-x-0 gutter-x mx:gap-x-0 flex flex-wrap gap-4 uppercase transition md:mt-0 md:flex-nowrap md:gap-0"
    >
        <li v-for="(item, i) in nav.items" class="col-span-3 transition md:flex-1">
            <NuxtLink
                :to="item.page.url"
                class="block transition"
                data-aos="fade-up"
                :data-aos-delay="100 * i"
            >
                <Asset :asset="item.page.image" class="aspect-[123/100] md:aspect-square" />

                <div class="py-4 md:p-4">
                    <div class="flex flex-wrap gap-x-1">
                        <span
                            class="icon-arrow-northeast whitespace-nowrap"
                            v-for="word in item.page.title.split(' ')"
                        >
                            {{ word }}
                        </span>
                    </div>
                </div>
            </NuxtLink>
        </li>
    </ul>
</template>

<style scoped>
    ul:hover li {
        @apply opacity-30;
    }

    ul li:hover {
        @apply opacity-100;
    }

    .icon-arrow-northeast:after {
        width: 11px;
        height: 11px;
    }

    li {
        flex-basis: calc(50% - 1rem / 2);
    }

    span:not(:last-child):after {
        content: none;
    }
</style>
