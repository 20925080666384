<script lang="ts" setup>
    import { Validation } from '~/data/Validation'

    export interface Props {
        field: Field
        showLabel?: boolean
        fitContent?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        open: false,
        showLabel: true,
        fitContent: false,
    })

    const placeholder = computed(() => {
        let placeholder = props.field.placeholder ?? ''

        if (props.field.validate?.indexOf(Validation.required) !== -1) {
            if (placeholder === '') {
                placeholder = props.field.display
            }

            placeholder += ' *'
        }

        return placeholder.toUpperCase()
    })
</script>

<template>
    <div
        class="input-frame my-4"
        :class="{
            'w-1/2': !fitContent && field.width == 50,
            'w-full': !fitContent && field.width == 100,
            'w-auto': fitContent,
            inline: fitContent,
            [`field-${field.handle}`]: true,
        }"
    >
        <label class="flex w-full flex-col-reverse items-start gap-2 text-base font-bold uppercase">
            <slot />

            <span class="peer-required:after:content-['*']" v-if="showLabel">
                {{ field.display }}
            </span>
        </label>
    </div>
</template>

<style>
    .input-frame {
        input,
        textarea,
        .multiselect,
        select {
            @apply outline-0;
            @apply transition;
            @apply bg-transparent;
            @apply font-normal;
            @apply w-full;
            @apply bg-white;

            &:focus {
                @apply shadow-lg shadow-dark/20;
            }
        }

        textarea {
            @apply h-[180px];
        }

        &:has(.multiselect.is-open) {
            z-index: 1;
        }
    }
</style>
