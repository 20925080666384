<script setup lang="ts">
    import { Entry } from '~/data/Entry'
    import { OffsetGroup } from '~/data/OffsetGroup'
    import { OffsetItem } from '~/data/OffsetItem'

    const partners = await Entry.all<Partner>('partners')
    const offset = new OffsetGroup(new OffsetItem(3, 3), new OffsetItem(1, 1))
    const marquee = ref()

    onMounted(() => {
        if (!import.meta.client || !marquee.value) {
            return
        }

        const items = marquee.value.querySelector('ul')

        // duplicate the logos list, twice, to create a seamless loop
        for (let i = 0; i < 2; i++) {
            const clone = items.cloneNode(true) // clone the logos list
            clone.setAttribute('aria-hidden', 'true') // mark it as hidden from screen readers
            items.parentNode.appendChild(clone) // append it to the DOM
        }

        marquee.value.classList.add('do-loop')
    })

    onUnmounted(() => {
        if (!import.meta.client || !marquee.value) {
            return
        }

        marquee.value.classList.remove('do-loop')

        // remove the cloned partner logos lists
        marquee.value.querySelectorAll('ul:not(:first-child)')?.foreach((clone: HTMLElement) => {
            clone.remove()
        })
    })
</script>

<template>
    <div class="block-gap-y">
        <div class="layout-grid block-gap-b -mt-4 sm:-mt-8 md:-mt-12">
            <h1 class="text-3xl uppercase" :class="offset.classes">Our Partners</h1>
        </div>

        <div
            ref="marquee"
            class="marquee -mt-4 mb-4 w-full overflow-hidden sm:-mt-8 sm:mb-8 md:-mt-12 md:mb-12"
        >
            <div class="flex h-full gap-3">
                <ul class="flex h-full gap-3">
                    <li
                        v-for="partner in partners"
                        class="flex aspect-square h-full select-none items-center justify-center bg-white"
                        target="_blank"
                    >
                        <a :href="partner.link" class="size-full">
                            <Asset
                                :asset="partner.logo"
                                :zoom="false"
                                fit="contain"
                                class="mt-0 size-full scale-[0.6] object-contain"
                            />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style>
    .marquee a .asset img {
        transform: scale(1);
    }
</style>

<style scoped>
    .marquee {
        height: clamp(8.625rem, 5.348rem + 13.981vw, 22.125rem);
    }

    .do-loop ul {
        animation: marquee 30s linear infinite;
    }

    .marquee:hover ul {
        animation-play-state: paused;
    }

    @keyframes marquee {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(calc(-100% - 0.75rem));
        }
    }
</style>
