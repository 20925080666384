<script lang="ts" setup>
    import { Nav, type NavItem } from '~/data/Nav'

    interface Props {
        handle: string
        itemClass?: string
        linkClass?: string
        arrows?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        itemClass: '',
        linkClass: '',
        arrows: false,
    })

    const nav = await Nav.fromHandle<NavItem>(props.handle)
</script>

<template>
    <ul class="nav-display">
        <li v-for="item in nav.items" class="block" :class="itemClass">
            <NuxtLink
                :to="item.page.url"
                class="group flex items-end justify-between hover:text-medium"
                :class="{ [linkClass]: true }"
                @click="useNavOpen().value = false"
            >
                <span class="transition">{{ item.page.title }}</span>

                <Arrow
                    v-if="arrows"
                    class="relative right-0 top-[-0.05em] group-hover:right-[-0.1em] group-hover:top-[-0.15em]"
                />
            </NuxtLink>
        </li>
    </ul>
</template>
